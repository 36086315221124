function Header(props) {

    const { nome, mensagem_incial, path_logo } = props.dadosPesquisa;

    return (
        <div>
            {path_logo && (
                <div className="row text-center mb-2">
                    <div className="col">
                        <img src={path_logo} alt="Logo Pesquisa" height="70px"></img>
                    </div>
                </div>
            )}
            <div className="row text-center">
                <div className="col">
                    <h4 className="my-3" style={{ "color": '#32325d', "fontWeight": "600" }}>{nome != null ? nome.toUpperCase() : ''}</h4>
                    <span className="my-2" style={{ "fontSize": "15px" }}>{mensagem_incial != null ? mensagem_incial.toUpperCase() : ''}</span>
                </div>
            </div>
            <div className="my-2">
                <p className="text-danger">* Campos Obrigatórios</p>
            </div>
        </div>
    );
}

export default Header