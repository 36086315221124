function EnviarRespostas(props) {

    const { loaderFormSubmit, messageButton } = props;

    return (
        <div className="text-center my-3">
            <button
                type="submit"
                className="btn btn-success col-md-4 p-2"
                disabled={loaderFormSubmit}>
                <div className="row">
                    <div className="col-md-2" hidden={!loaderFormSubmit}>
                        <i className="spinner-border" hidden={!loaderFormSubmit}></i>
                    </div>
                    <div className={ !loaderFormSubmit ? "col-md-12" : "col-md-10"}>
                        <p className="m-0">{messageButton}</p>
                    </div>
                </div>
            </button>
        </div>
    );
}

export default EnviarRespostas