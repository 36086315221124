function Loader(props) {
    const { loaderMessage } = props; 
    return (
        <div className="text-center">
            <div className="spinner-border mb-2" role="status">
            </div>
            <p className="visually">{ loaderMessage }</p>
        </div>

    )
}

export default Loader