function CheckBox(props) {

    const { id, opcoes } = props.props.dados;
    const { handleInputChange } = props;

    return (
        <div className="container row justify-content-center px-1 py-2 mx-0">
            {
                opcoes.map((opcao, index) => {
                    return (
                        <div key={index} className="form-check col-md-6 col-sm-6 col-lg-6 px-0">
                            <label className="form-check col-md-11 col-sm-10 mx-2 my-1 btn btn-md border text-wrap" style={{ 'fontSize': '15px' }} htmlFor={`checkbox_pergunta_${id}_${index}`} >
                                <input
                                    className="form-check-input ms-2 col-sm-3 me-md-1"
                                    type="checkbox"
                                    onChange={handleInputChange}
                                    name={`${id}`}
                                    value={opcao}
                                    id={`checkbox_pergunta_${id}_${index}`}
                                />
                                {opcao}
                            </label>
                        </div>
                    )
                })
            }

        </div>
    )
}

export default CheckBox