function Escala(props) {

    const { obrigatoria, id, opcoes } = props.props.dados;
    const { handleInputChange } = props;

    return (
        <div className="container">
            <div className="btn-group my-4 d-flex justify-content-center">
                <div className="btn-group" role="group">
                    {opcoes.map((opcao, index) =>
                        <div key={`escala_pergunta_${id}_${index}`} >
                            <input
                                onChange={handleInputChange}
                                type="radio"
                                className="btn-check"
                                name={`${id}`}
                                value={opcao}
                                id={`escala_pergunta_${id}_${index}`}
                                required={obrigatoria} />
                            <label
                                className="btn btn-light border shadow px-1 px-sm-2 px-lg-3 mx-1"
                                htmlFor={`escala_pergunta_${id}_${index}`}>{opcao}</label>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-3 col-md-3">
                    <p>Nada provável</p>
                </div>
                <div className="col-6 col-md-6"></div>
                <div className="col-3 col-md-3">
                    <p>Muito provável</p>
                </div>
            </div>
        </div>
    );
}

export default Escala