import './App.css';
import Formulario from './components/Formulario';

function App() {
  return (
    <Formulario />
  );
}

export default App;
