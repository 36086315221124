import { useEffect } from "react";

function PesquisaIndisponivel(props) {

    const { mensagem, type } = props;

    useEffect(() => {
        
    }, [mensagem]);
    
    return (
        <div className="row justify-content-center">
            <div className="col-lg-6 col-md-6 text-center">
                <div className={type === "success" ? "alert alert-success" : "alert alert-danger"}>
                    <span>{mensagem}</span>
                </div>
            </div>
        </div>
    )

}

export default PesquisaIndisponivel