import CheckBox from "./CheckBox";
import Escala from "./Escala";
import Radios from "./Radios";
import Select from "./Select";
import Texto from "./Texto";

function Pergunta(props) {

    const { handleInputChange, dados, formValues } = props;

    return (
        <div className="container">
            <hr></hr>
            <p>
                {/* {props.numero_pergunta} -  */}
                {dados.texto.toUpperCase()} {dados.obrigatoria &&
                <b style={{ color: 'red' }}>*</b>
            }
            </p>
            {(() => {
                switch (dados.tipo) {
                    case 'escala':
                        return <Escala props={props} handleInputChange={handleInputChange} />
                    case 'radio':
                        return <Radios props={props} handleInputChange={handleInputChange} />
                    case 'select':
                        return <Select props={props} handleInputChange={handleInputChange} />
                    case 'checkbox':
                        return <CheckBox props={props} handleInputChange={handleInputChange} />
                    case 'texto':
                        return <Texto props={props} handleInputChange={handleInputChange} formValues={formValues} />
                    default:
                        return null;
                }
            })()}
        </div>
    );
}

export default Pergunta