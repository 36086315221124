function Texto(props) {

    const { obrigatoria, id, placeholder } = props.props.dados;

    const { handleInputChange } = props;

    return (
        <div className="mb-3">
            <textarea
                className="form-control"
                id={id}
                rows="3"
                onChange={(e) => {
                    handleInputChange(e);
                }
                }
                name={`${id}`}
                placeholder={placeholder}
                required={obrigatoria}
            />
        </div>
    );
}

export default Texto