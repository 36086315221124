function Radios(props) {

    const { obrigatoria, id, opcoes } = props.props.dados;
    const { handleInputChange } = props;

    return (
        <div className="container">
            {opcoes.map((opcao, index) =>
                <div className="form-check" key={index}>
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`${id}`}
                        id={`radio_pergunta_${id}_${index}`}
                        value={opcao}
                        onChange={handleInputChange}
                        required={obrigatoria}
                    />
                    <label className="form-check-label" htmlFor={`radio_pergunta_${id}_${index}`}>
                        {opcao}
                    </label>
                </div>
            )}
        </div>
    );
}

export default Radios