function Select(props) {

    const { obrigatoria, id, opcoes } = props.props.dados;

    return (
        <select
            className="form-select"
            defaultValue={'Selecione uma opção...'}
            aria-label="Default select example"
            name={`${id}`}
            required={obrigatoria}> 

            <option disabled value="Selecione uma opção...">Selecione uma opção...</option>
            {opcoes.map((opcao, index) =>
                <option
                    key={`options_pergunta_${id}_${index}`}
                    value={opcao}>{opcao}
                </option>
            )}
        </select>
    )

}

export default Select
